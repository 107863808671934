import create from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { enableMapSet } from 'immer'

enableMapSet()

import { api } from './api'

import type { APIQuestionConfig, QuestionConfig, Questions, Question, Answers, APIResponse } from '../../../../shared/types'

export type { QuestionConfig, Questions, Question, Answers }

export type QuestionId = string

export type EditQuestionState = {
  question: Partial<Question>,
  originalId?: QuestionId,
}

export interface QuestionStore {
  version: number,
  questions: Questions,
  questionList: Question[],
  enabledQuestionList: Question[],
  chosenQuestions?: [number, number, number],
  userId: number,
  answers: Answers,
  loaded: boolean,
  questionIndex: number,
  hasChanges: boolean,
  unsavedQuestions: Set<QuestionId>,

  addQuestion: (question: Question) => void,
  editQuestionState: EditQuestionState | null,
  editQuestion: (state: EditQuestionState | null) => void,
  commitEditQuestion: () => void,
  updateEditQuestion: (question: Partial<Question>) => void,
  updateEditQuestionOption: (i: number, value: string | null) => void,

  chooseRandomQuestions: () => void,
  chooseSequentialQuestions: () => void,
  replaceSequentialQuestion: (pos: number) => void,
  chooseCategoryQuestions: (categories: [string, string, string]) => void,
  replaceCategoryQuestion: (pos: number) => void,

  reset: () => void,
  answer: (key: QuestionId, answer: number | string) => void,

  updateQuestion: (id: QuestionId, question: Partial<Question>) => void,
  moveQuestion: (source: number, dest: number) => void,
  removeQuestion: (id: QuestionId) => void,


  fetchQuestionConfig: () => Promise<void>,
  saveQuestionConfig: () => Promise<void>,
}

export const N_OPTIONS = 5

const sgenId = () => Math.random().toFixed(10).slice(2)
const genId = () => +sgenId()

const shuffle = (a: any[]) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]]
  }
}

const isLength3 = <T>(a: T[]): a is [T, T, T] => a.length === 3

function assertLength3<T>(a: T[]): asserts a is [T, T, T] {
  if (!isLength3(a)) throw new Error('length check failed')
}

export const isValidQuestion = (q: Partial<Question>): q is Question => {
  const idValid = q.id != null && q.id.length > 0
  if (!idValid) return false

  const questionValid = q.question != null &&  q.question.length > 0
  if (!questionValid) return false

  const options = q.options || []

  return options.length === N_OPTIONS && options.every(x => x.length > 0)
}

export const checkValidQuestions =
  (questions: Questions): boolean =>
    Object.values(questions).every(isValidQuestion)

export const sampleAnswers = async (id: QuestionId, count: number): Promise<number[] | null> => {
  try {
    const params = {questionId: id, count: ''+count}
    return (await api('responses/sample', {params})).data
  }
  catch (_) {
    return null
  }
}

export const useQuestionStore = create(immer<QuestionStore>((set, get) => ({
  version: 0,
  questions: {},
  questionList: [],
  enabledQuestionList: [],
  chosenQuestions: undefined,
  questionIndex: 0,
  userId: genId(),
  answers: {},
  loaded: false,
  hasChanges: false,
  editQuestionState: null,
  unsavedQuestions: new Set(),

  reset: () => {
    const questionIndex = (get().questionIndex + 3) % get().enabledQuestionList.length

    set({userId: genId(), answers: {}, questionIndex })
  },

  chooseCategoryQuestions: (categories: [string, string, string]) => {
    const questions = get().enabledQuestionList
    const len = questions.length

    let chosen = get().chosenQuestions || categories.map(_ => 0) as [number, number, number]
    chosen = Array.from(chosen) as [number, number, number]

    for (const [i, category] of categories.entries()) {
      let qi = chosen[i]
      const startqi = qi
      while (questions[qi].subject !== category) {
        // console.log('trying', qi, questions[qi].subject, category)
        qi = (qi + 1) % len

        if (qi === startqi) throw new Error(`couldn't find category ${category}`)
      }

      chosen[i] = qi
    }

    set({chosenQuestions: chosen})
  },

  replaceCategoryQuestion: (pos: number) => {
    const questions = get().enabledQuestionList
    const len = questions.length
    let chosen = get().chosenQuestions

    if (!chosen) return

    chosen = Array.from(chosen) as [number, number, number]

    const category = questions[chosen[pos]].subject

    let qi = chosen[pos]
    const startqi = qi
    do {
      qi = (qi + 1) % len

      // console.log('trying', qi, questions[qi].subject, category)

      if (qi === startqi) throw new Error(`couldn't find category ${category}`)
    } while (questions[qi].subject !== category)

    chosen[pos] = qi

    set({chosenQuestions: chosen})
  },

  chooseRandomQuestions: () => {
    const questions = get().enabledQuestionList

    const indices = Array.from(questions.keys())
    shuffle(indices)

    const chosen: number[] = []
    for (const i of indices) {
      if (chosen.length === 3) break
      if (!questions[i].enabled) continue
      if (chosen.some((j) => questions[i].subject === questions[j].subject)) continue
      chosen.push(i)
    }
    if (!isLength3(chosen)) throw new Error('Not enough questions')

    // console.log('chooseQuestions', chosen)
    set({chosenQuestions: chosen})
  },

  chooseSequentialQuestions: () => {
    const len = get().enabledQuestionList.length
    const qi = get().questionIndex

    const chosen = [qi, (qi+1) % len, (qi+2) % len]

    assertLength3(chosen)

    set({chosenQuestions: chosen})
  },

  replaceSequentialQuestion: (pos: number) => {
    const len = get().enabledQuestionList.length

    const questionIndex = (get().questionIndex + 1) % len

    const qi = (questionIndex + 2) % len

    set(state => {
      if (!state.chosenQuestions) return
      state.questionIndex = questionIndex

      state.chosenQuestions[pos] = qi
    })
  },

  saveQuestionConfig: async () => {
    if (!get().loaded) throw new Error('attempt to set questions before questions are loaded')

    const {version, questions, questionList} = get()

    if (!checkValidQuestions(questions)) throw new Error('invalid questions')

    if (Object.keys(questions).length !== questionList.length) {
      throw new Error('questions/questionList mismatch')
    }

    const orderedQuestions: Questions = {}
    for (const q of questionList) orderedQuestions[q.id] = q

    if (!checkValidQuestions(orderedQuestions)) throw new Error('invalid questionList')

    const config: APIQuestionConfig = {version: version + 1, questions: orderedQuestions}

    await api.post('questions', config)

    set(state => {
      state.version += 1
      state.hasChanges = false
      state.unsavedQuestions.clear()
    })
  },

  fetchQuestionConfig: async () => {
    const config: APIQuestionConfig = (await api('questions')).data

    const {version, questions} = config

    const questionList = Object.values(questions).map(q => ({...q}))
    const enabledQuestionList = questionList.filter(q => q.enabled)

    set({questions, questionList, enabledQuestionList, version, loaded: true})
  },

  answer: (question_id: QuestionId, answer: number | string) => {
    console.log('updating', question_id, 'to', answer)

    const data: APIResponse = {
      user_id: get().userId,
      question_id,
      answer
    }

    api.post('responses', data)

    set(state => ({
      answers: {...state.answers, [question_id]: answer}
    }))
  },

  updateQuestion(id: QuestionId, question: Partial<Question>) {
    set(state => {
      if (question.id && id !== question.id) {
        throw new Error("can't change question id")
      }

      // const oldQuestion = state.questions[id]
      // const newQuestion = Object.assign({}, oldQuestion, question)

      // console.log('oldQuestion', Object.assign({}, oldQuestion))
      // console.log('newQuestion', newQuestion)

      // state.questions[id] = newQuestion
      Object.assign(state.questions[id], {...question})
      Object.assign(state.questionList.find(q => q.id === id)!, {...question})

      // state.questionList = state.questionList.map(q => q.id === id ? {...newQuestion} : q)
      // state.questionList = state.questionList.map(q => q === oldQuestion ? newQuestion : q)
      state.enabledQuestionList = state.questionList.filter(q => q.enabled)

      state.hasChanges = true
    })
  },

  moveQuestion(source: number, dest: number) {
    set(state => {
      const ql = state.questionList
      ql.splice(dest, 0, ...ql.splice(source, 1))

      state.enabledQuestionList = state.questionList.filter(q => q.enabled)

      state.hasChanges = true
    })
  },

  removeQuestion(id: QuestionId) {
    set(state => {
      delete state.questions[id]
      state.questionList = state.questionList.filter(q => q.id !== id)
      state.enabledQuestionList = state.questionList.filter(q => q.enabled)

      state.hasChanges = true
    })
  },

  addQuestion(question: Question) {
    set(state => {
      if (state.questions[question.id]) {
        throw new Error('duplicate question id')
      }

      console.log('addQuestion!', question.id, question)

      state.questions[question.id] = question
      state.questionList.push(question)
      if (question.enabled) state.enabledQuestionList.push(question)

      state.unsavedQuestions.add(question.id)

      state.hasChanges = true
    })
  },

  editQuestion(eqs: EditQuestionState | null) {
    set(state => {
      state.editQuestionState = eqs
    })
  },

  commitEditQuestion() {
    set(state => {
      if (!state.editQuestionState) return
      const {question, originalId} = state.editQuestionState

      console.log('question commit', Object.assign({}, question))

      if (!isValidQuestion(question)) throw new Error('tried to commit invalid edit question')

      question.options = question.options || []
      const id = question.id

      if (originalId && originalId !== id) {
        state.questionList = state.questionList.filter(q => q.id !== originalId)
        state.enabledQuestionList = state.questionList.filter(q => q.enabled)

        state.unsavedQuestions.delete(originalId)
      }

      if (!state.questions[id]) {
        state.questions[question.id] = question
        state.questionList.push(question)
        if (question.enabled) state.enabledQuestionList.push(question)

        state.unsavedQuestions.add(question.id)

      }
      else {
        console.log('updateQuestion')

        Object.assign(state.questions[id], question)
        Object.assign(state.questionList.find(q => q.id === id)!, question)

        state.enabledQuestionList = state.questionList.filter(q => q.enabled)
      }

      state.editQuestionState = null
      state.hasChanges = true
    })
  },


  updateEditQuestion(question: Partial<Question>) {
    set(state => {
      if (state.editQuestionState) {
        Object.assign(state.editQuestionState.question, question)
      }
    })
  },

  updateEditQuestionOption(i: number, value: string | null) {
    set(state => {
      const eqs = state.editQuestionState
      if (!eqs) return

      let options = eqs.question.options
      if (!options) {
        if (value == null) return

        options = eqs.question.options = []
      }

      if (value == null) {
        options.splice(i, 1)
        if (options.length === 0) {
          delete eqs.question.options
        }
      }
      else {
        while (i > options.length - 1) {
          options.push('')
        }
        options[i] = value
      }
    })
  },


})))

